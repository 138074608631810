.about {
  width: 100%;
  font-size: large;
  color: red;
}

.h1 {
  font-size: 30px;
}

.p {
  font-size: 25px;
}

.p2 {
  font-size: 25px;
  width: 100%;
}

.imageSize {
  width: 90%;
  box-shadow: 10px 10px orange;
  border: black solid 10px;
}

.horizontalImage {
  width: 100%;
  box-shadow: 10px 10px orange;
  border: black solid 10px;
}


.flex {
  display: flex;
}

.overWrite {
  box-shadow: none !important;
  grid-template-areas: none !important;
}

.aboutUsContainer {
  padding: 5%;
}
