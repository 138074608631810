.image {
  max-width: 100%;
  max-height: 600px;
  border-radius: 20px;
  vertical-align: middle;
  flex-basis: auto;
  margin-bottom: 20px;
}

.imageContainer {
  margin: 0% 2%;
  padding: 2% 2%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* flex-basis: auto; */
}

.description {
  margin: 1%;
  font-family: helvetica;
  font-weight: bold;
  font-size: 25px;
}

.slideContainer {
  margin-top: auto;
  margin-bottom: auto;
  padding: 0% 0%;
}

.slideWrapper {
  margin: 0% 0%;
  padding: 0% 0%;
}
