
.menuHeader{
    width: 100%;
    position: relative;
    
}

.menuImage1{
    width:70%;
    height:40%;
   padding-left: 14.5%;
   padding-bottom: 10%;
   padding-top: 8%;

}

.menuImage2{
    width: 70%;
    height: 40%;
    padding-left: 14.5%;
    padding-top: 10%;
    
}

.footer{
    padding-top: 13%;
}

.boxStyle {
    position: 'absolute';
    top: '50%';
    left: '50%';
    transform: 'translate(-50%, -50%)';
    width: 700;
    background-color: 'background.paper';
    border: '2px solid #000';
    box-shadow: 24;
    
  }

.menuImageLightBox1{
    width: 66%;
    height: 50%;
    padding-left: 17%;
    padding-top: 1%;
    
}
.menuImageLightBox2{
    width: 66%;
    height: 50%;
    padding-left: 17%;
    padding-top: 1%;
    
}

.menuLightBoxCloseButton{
    
    font-size: 110% !important;
    position: absolute !important; 
    right: 3vw;
    top: 2vh;
    
    
    
}

  




